import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

axios.defaults.baseURL = process.env.REACT_APP_BASE_URI;
export const instanceApi = axios.create();

export const api = {
  async get({ uri }) {
    return await instanceApi.get(`${uri}`);
  },

  async post({ uri, data }) {
    return await instanceApi.post(`${uri}`, data);
  },

  async put({ uri, data }) {
    return await instanceApi.put(`${uri}`, data);
  },

  async delete({ uri, data }) {
    return await instanceApi.delete(`${uri}`, data);
  },
};

const logOnDev = (message, logType) => {
  if (process.env.MODE === 'development') {
    console[logType](message);
  }
};

const onRequest = (config) => {
  logOnDev(`[request] [${JSON.stringify(config)}]`, 'info');
  const token = cookies.get('token');
  const { headers = {} } = config;

  if (token) {
    headers.Authorization = token;
  }

  return { ...config, headers };
};

const onRequestError = (error) => {
  logOnDev(`[request error] [${JSON.stringify(error)}]`, 'error');

  return Promise.reject(error);
};

const onResponse = (response) => {
  logOnDev(`[response] [${JSON.stringify(response)}]`, 'info');

  return response;
};

const onResponseError = (error) => {
  logOnDev(`[response error] [${JSON.stringify(error)}]`, 'error');
  const { status } = error;

  if (status === 401) {
    cookies.remove('token');
  }

  return Promise.reject(error);
};

export function setupInterceptors(axiosInstance) {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);

  return axiosInstance;
}

setupInterceptors(instanceApi);
