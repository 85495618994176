import { useTranslation } from 'react-i18next';

import '../Footer.scss';

import { ReactComponent as Vk } from '../../../../assets/images/Footer/vk.svg';
import { ReactComponent as Wu } from '../../../../assets/images/Header/whatsap.svg';

export const FollowMe = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="follow-me">
      <div className="follow-me-title">{t('Footer.followme')}</div>
      <div className="follow-me-image-wrapper">
        <a
          className="follow-me-image hover"
          href="https://vk.com/club_event360"
          target="_blank"
          rel="noreferrer"
        >
          <Vk />
        </a>
        <a
          className="follow-me-image hover"
          href="https://wa.me/79304082371"
          target="_blank"
          rel="noreferrer"
        >
          <Wu fill="#d4af37" />
        </a>
      </div>
    </div>
  );
};
