import * as ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { Auth } from './components/Admin/Auth';
import { Create } from './components/Admin/Create';
import Download from './components/Admin/Download';
import { Edit } from './components/Admin/Edit';
import { EditList } from './components/Admin/EditList';
import { Users } from './components/Admin/Users';
import { TranslationProvider } from './config/providers/TranslationProvider';
import { About } from './page/About';
import { AboutArea } from './page/AboutArea';
import { AboutService } from './page/AboutService';
import { Area } from './page/Area';
import { Contact } from './page/Contact';
import { Home } from './page/Home';
import { Project } from './page/Project';
import { Service } from './page/Service';

import './index.scss';

enum pageEnum {
  HOME = '/',
  ABOUT = '/about',
  AUTH = '/auth',
  SERVICE = '/service',
  ABOUT_SERVICE = '/about-service',
  PROJECT = '/project',
  AREA = '/area',
  ABOUT_AREA = '/area-about',
  CONTACT = '/contact',
  CREATE = '/create',
  EDIT = '/edit/:id',
  EDIT_LIST = '/edit-list',
  USERS = '/users',
  DOWNLOAD = '/download',
}

const router = createBrowserRouter([
  {
    path: pageEnum.HOME,
    element: <Home />,
  },
  {
    path: pageEnum.ABOUT,
    element: <About />,
  },
  {
    path: pageEnum.ABOUT_AREA,
    element: <AboutArea />,
  },
  {
    path: pageEnum.SERVICE,
    element: <Service />,
  },
  {
    path: pageEnum.ABOUT_SERVICE,
    element: <AboutService />,
  },
  {
    path: pageEnum.PROJECT,
    element: <Project />,
  },
  {
    path: pageEnum.AREA,
    element: <Area />,
  },
  {
    path: pageEnum.CONTACT,
    element: <Contact />,
  },
  {
    path: pageEnum.AUTH,
    element: <Auth />,
  },
  {
    path: pageEnum.CREATE,
    element: <Create />,
  },
  {
    path: pageEnum.EDIT_LIST,
    element: <EditList />,
  },
  {
    path: pageEnum.DOWNLOAD,
    element: <Download />,
  },
  {
    path: pageEnum.EDIT,
    element: <Edit />,
  },

  {
    path: pageEnum.USERS,
    element: <Users />,
  },
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <TranslationProvider>
    <RouterProvider router={router} />
  </TranslationProvider>,
);
