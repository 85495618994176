import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { api } from '../../api/axios.service';
import './Admin.scss';

export const Auth = (): JSX.Element => {
  const navigate = useNavigate();
  const [login, setLogin] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [authError, setAuthError] = useState<string>();
  const cookies = new Cookies();

  const handlerLogin = () => {
    api
      .post({
        uri: '/login',
        data: {
          login: login,
          password: password,
        },
      })
      .then(({ data }) => {
        if (typeof data.token !== 'undefined') {
          cookies.set('token', data.token);
          navigate('/edit-list');
        }
      })
      .catch(({ status }) => {
        if (status === 401) {
          setAuthError('Пользователь не активен в системе');
        }
        if (status === 500) {
          setAuthError('Пользователь не найден');
        }
      });
  };
  authError;

  return (
    <div className="flex">
      <div className="flex-columns">
        <input
          className="loginInput"
          type="text"
          onChange={(e) => setLogin(e.target.value)}
        />
        <input
          className="loginInput"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className="loginButton" onClick={handlerLogin}>
          Login
        </button>
        <span className="loginError">{authError}</span>
      </div>
    </div>
  );
};
