import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { api } from '../../../api/axios.service';
import { SuccessForm } from '../../SuccessForm';

export const MainForm = (): JSX.Element => {
  const { t } = useTranslation();

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const defaultValue = [
    { name: 'name', value: '', require: false },
    { name: 'email', value: '', require: true },
    { name: 'phone', value: '', require: true },
    { name: 'robot', value: 'false', require: true },
    { name: 'save', value: 'true', require: true },
  ];

  const getDefaultValue = (name): string => {
    const find = form.find((item) => item.name === name);
    if (find.value !== '') {
      return find.value;
    } else {
      return '';
    }
  };

  const getDefaultChecked = (name): boolean => {
    const find = form.find((item) => item.name === name);
    if (find.value !== 'false') {
      return Boolean(find.value);
    } else {
      return false;
    }
  };

  const [form, setForm] = useState(defaultValue);

  const handlerForm = async () => {
    const error = form
      .map(({ name, value, require }) => {
        if (require) {
          if (value === '' || value === 'false') {
            return { name: name, value: 'error' };
          }
        }
      })
      .filter(Boolean);

    if (error.length === 0) {
      const data = {};
      form.forEach(({ name, value }) => (data[name] = value));

      api.post({ uri: '/mail', data: data }).then(({ status }) => {
        if (status === 200) {
          setSuccess(true);

          setTimeout(() => {
            setSuccess(false);
            setForm(defaultValue);
          }, 2000);
        }
      });
    } else {
      setError(t('About.form.error'));
    }
  };

  const handlerFormInput = (e, name) => {
    const { value } = e.target;
    const input = form.find((item) => item.name === name);
    const filter = form.filter((item) => item.name !== name);
    input.value = value;
    setForm([...filter, input]);
    setError(null);
  };

  const handlerFormCheckbox = (e, name) => {
    const { checked } = e.target;
    const input = form.find((item) => item.name === name);
    const filter = form.filter((item) => item.name !== name);
    input.value = checked.toString();
    setForm([...filter, input]);
    setError(null);
  };

  return (
    <>
      <div className="container pt-5 pb-2">
        <p className="about-title text-center pb-2">{t('About.form.title')} </p>
        <div className="d-flex align-content-center justify-content-center gap-5 about-input-wrapper">
          <input
            className="about-input"
            type="text"
            placeholder={t('About.form.name')}
            onChange={(e) => handlerFormInput(e, 'name')}
            value={getDefaultValue('name')}
          />
          <input
            className="about-input"
            type="text"
            placeholder={t('About.form.email')}
            onChange={(e) => handlerFormInput(e, 'email')}
            value={getDefaultValue('email')}
          />
          <input
            className="about-input"
            type="text"
            placeholder={t('About.form.phone')}
            onChange={(e) => handlerFormInput(e, 'phone')}
            value={getDefaultValue('phone')}
          />
        </div>
        <div className="d-flex align-content-center justify-content-center gap-5 pt-3 about-input-wrapper">
          <p className="about-checkboxs mb-0">{t('About.form.required')}</p>
          <div className="about-checkboxs">
            <label className="d-flex gap-2">
              <input
                className="about-checkbox"
                type="checkbox"
                onChange={(e) => handlerFormCheckbox(e, 'robot')}
                checked={getDefaultChecked('robot')}
              />
              {t('About.form.robot')}
            </label>
            <label className="d-flex gap-2">
              <input
                className="about-checkbox"
                type="checkbox"
                defaultChecked={true}
                onChange={(e) => handlerFormCheckbox(e, 'save')}
                checked={getDefaultChecked('save')}
              />
              {t('About.form.yes')}
            </label>
          </div>
        </div>
      </div>
      {error && (
        <div className="alert-about alert alert-danger text-center">
          {error}
        </div>
      )}
      {success && (
        <SuccessForm
          text={t('About.form.success')}
          subtext={t('About.form.success-bottom')}
        />
      )}

      <div className="main-button-about" onClick={handlerForm}>
        {t('About.form.send')}
      </div>
    </>
  );
};
