import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { Logout } from './Logout';
import { api } from '../../api/axios.service';

import './Admin.scss';

export const Download = (): JSX.Element => {
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const cookies = new Cookies();

  useEffect(() => {
    if (!cookies.get('token')) {
      navigate('/auth');
    }
  }, []);

  const handlerUpdateFile = (name) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', name);

    api
      .post({ uri: '/change-download', data: formData })
      .then(({ data, status }) => {
        status === 200 && console.log(data);
      });
  };

  const downloadMap = [
    { id: 1, title: 'Календарь', name: 'calendar.pdf' },
    { id: 2, title: 'Каталог mice', name: 'catalog.pdf' },
    { id: 3, title: 'Бриф', name: 'event360_brif.docx' },
    { id: 4, title: 'Презентация', name: 'event360_present.pdf' },
  ];

  return (
    <div className="container">
      <div className="">
        <div className="flex-group">
          <h1>Обновление загружаемых документов</h1>
          <button onClick={() => navigate('/edit-list')}>{'<--'} Назад</button>
          <Logout />
        </div>
        <div className="downloadWrapper">
          {downloadMap?.map((item) => (
            <div key={item.id} className="downloadCard">
              <h5>Документ: {item.title}</h5>
              <h6>{item.name}</h6>
              <input type="file" onChange={(e) => setFile(e.target.files[0])} />
              <button onClick={() => handlerUpdateFile(item.name)}>
                Загрузить
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Download;
