import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { Logout } from './Logout';
import User from './User';
import { api } from '../../api/axios.service';

import './Admin.scss';

export const Users = (): JSX.Element => {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);

  const navigate = useNavigate();
  const cookies = new Cookies();

  useEffect(() => {
    if (!cookies.get('token')) {
      navigate('/auth');
    }
  }, []);

  useEffect(() => {
    api.get({ uri: '/users' }).then(({ data, status }) => {
      status === 200 && setUsers(data);
    });
  }, []);

  return (
    <div className="container">
      <div className="">
        <div className="flex-group">
          <h1>Пользователи</h1>
          <button onClick={() => navigate('/edit-list')}>{'<--'} Назад</button>
          <Logout />
        </div>
        <div className="usersWrapper">
          {users?.map((item) => (
            <div key={item.id} className="cardUser">
              <h4>Логин: {item.user}</h4>
              <p>Статус: {item.isActive === 1 ? 'Активен' : 'Не активен'}</p>
              <button
                onClick={() =>
                  user === null || item?.id !== user?.id
                    ? setUser(item)
                    : setUser(null)
                }
              >
                {item?.id !== user?.id ? 'Показать' : 'Скрыть'}
              </button>

              {item?.id === user?.id && <User data={item} />}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Users;
