import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { api } from '../../../api/axios.service';
import { findAndReplaceBreak } from '../../../shared/utils/findAndReplaceBreak';
import { SuccessForm } from '../../SuccessForm';

export const DownloadForm = (): JSX.Element => {
  const { t } = useTranslation();

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const defaultValue = [{ name: 'email', value: '', require: true }];

  const [form, setForm] = useState(defaultValue);

  const getDefaultValue = (name): string => {
    const find = form.find((item) => item.name === name);
    if (find.value !== '') {
      return find.value;
    } else {
      return '';
    }
  };

  const handlerForm = async () => {
    const error = form
      .map(({ name, value, require }) => {
        if (require) {
          if (value === '' || value === 'false') {
            return { name: name, value: 'error' };
          }
        }
      })
      .filter(Boolean);

    if (error.length === 0) {
      const data = {};
      form.forEach(({ name, value }) => (data[name] = value));

      api.post({ uri: '/calendar', data: data }).then(({ status }) => {
        if (status === 200) {
          setSuccess(true);

          setTimeout(() => {
            setSuccess(false);
            setForm(defaultValue);
          }, 2000);
        }
      });
    } else {
      setError(t('About.form.error'));
    }
  };

  const handlerFormInput = (e, name) => {
    const { value } = e.target;
    const input = form.find((item) => item.name === name);
    const filter = form.filter((item) => item.name !== name);
    input.value = value;
    setForm([...filter, input]);
    setError(null);
  };

  return (
    <div className="download-form">
      <div className="download-form-text">
        {findAndReplaceBreak(t('Main.download.title'))}
      </div>
      <input
        className="download-form-input"
        type="text"
        placeholder={t('Main.download.email')}
        onChange={(e) => handlerFormInput(e, 'email')}
        value={getDefaultValue('email')}
      />
      {error && (
        <div className="alert-about alert alert-danger text-center">
          {error}
        </div>
      )}
      {success && (
        <SuccessForm
          text={t('About.form.success')}
          subtext={t('About.form.success-bottom')}
        />
      )}
      <button className="download-form-button" onClick={handlerForm}>
        {t('Main.download.send')}
      </button>
    </div>
  );
};
