import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { HelloImages } from '../components/HelloImages';
import { Services } from '../components/Services';

export const Service = (): JSX.Element => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('Title.Service');
  }, [t]);

  return (
    <>
      <Header />
      <HelloImages title={t('HelloImage.Service')} img="service" />
      <Services />
      <Footer />
    </>
  );
};
