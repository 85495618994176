import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { Logout } from './Logout';
import { api } from '../../api/axios.service';

import './Admin.scss';

export const EditList = (): JSX.Element => {
  const navigate = useNavigate();
  const cookies = new Cookies();

  const [prefixs, setPrefix] = useState('ru');
  const [update, setUpdate] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    if (update) {
      api
        .get({ uri: `/projects-admin/${prefixs}` })
        .then(({ status, data }) => {
          status === 200 && setList(data);
        });
    }
    setTimeout(() => {
      setUpdate(false);
    }, 250);
  }, [update]);

  useEffect(() => {
    api.get({ uri: `/projects-admin/${prefixs}` }).then(({ status, data }) => {
      status === 200 && setList(data);
    });
  }, [prefixs]);

  useEffect(() => {
    if (!cookies.get('token')) {
      navigate('/auth');
    }
  }, []);

  const buttonMaps = [
    { id: 1, prefix: 'ru' },
    { id: 2, prefix: 'en' },
    { id: 3, prefix: 'zh' },
  ];

  const handlerEdit = (id) => {
    navigate(`/edit/${id}`);
  };

  const handlerOrders = ({ id, orders }) => {
    api
      .put({
        uri: `/update-orders`,
        data: {
          id: id,
          orders: orders,
        },
      })
      .then(({ status }) => {
        status === 200 && setUpdate(true);
      });
  };

  const handlerDelete = (id) => {
    const q = window.confirm('Удалить проект?');

    if (q) {
      api
        .get({
          uri: `/delete/${id}`,
        })
        .then(({ status }) => {
          status === 200 && setUpdate(true);
        });
    }
  };

  return (
    <div className="container">
      <div className="d-flex gap-2 justify-space-between align-center">
        <h1 className="p-1">Проекты</h1>
        <button onClick={() => navigate('/users')}>
          Управление пользователями
        </button>
        <button onClick={() => navigate('/download')}>
          Обновить документы
        </button>
        <button onClick={() => navigate('/create')}>+ Создать проект</button>
        <Logout />
      </div>
      <div className="d-flex gap-2">
        {buttonMaps.map(({ id, prefix }) => (
          <button
            className={prefix === prefixs && 'active'}
            key={id}
            onClick={() => setPrefix(prefix)}
          >
            {prefix}
          </button>
        ))}
      </div>
      <div>
        {list.map(({ id, title, date, orders }) => (
          <div key={id} className="d-flex gap-2">
            <div className="editListItemId">{id}</div>
            <div onClick={() => handlerEdit(id)} className="editListItem">
              <div>{title}</div>
              <div>{date}</div>
            </div>
            <input
              className="editListNumber"
              type="number"
              defaultValue={orders}
              onChange={(e) =>
                handlerOrders({ id: id, orders: e.target.value })
              }
            />
            <button
              className="deleteProjects"
              onClick={() => handlerDelete(id)}
            >
              Удалить
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
