import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { withSelectors } from './utils/withSelectors';
import locales from '../config/locales';

export const useAppStore = withSelectors(
  create()(
    devtools(
      (set, get) => ({
        locales: locales,

        setProjectByTag: (tag, project) => {
          set((state) => {
            const updatedLocales = { ...state.locales };

            const projectTranslations = updatedLocales[tag].translation.Project;

            project.forEach((item) => {
              const [key] = Object.keys(item);
              projectTranslations[key] = item[key];
            });

            return { locales: updatedLocales };
          });
        },
      }),

      { name: '[event-360] appStore' },
    ),
  ),
);
