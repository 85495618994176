import { use } from 'i18next';
import { Fragment, ReactElement, ReactNode } from 'react';
import { initReactI18next } from 'react-i18next';

import { api } from '../../api/axios.service';
import { useAppStore } from '../../store/appStore';

interface TranslationProviderProps {
  children: ReactNode;
}

const language = window.localStorage.getItem('language');

const getLang = () => (language ? [language.replaceAll("'", '')] : ['ru']);

const locales = useAppStore.getState().locales;
const setProjectByTag = useAppStore.getState().setProjectByTag;

const getLocalesByTag = async (tag) =>
  await api.get({ uri: `/projects/${tag}` });

const { data: ru } = await getLocalesByTag('ru');
setProjectByTag('ru', ru);
const { data: en } = await getLocalesByTag('en');
setProjectByTag('en', en);
const { data: zh } = await getLocalesByTag('zh');
setProjectByTag('zh', zh);

use(initReactI18next).init({
  resources: locales,
  fallbackLng: getLang(),
  interpolation: {
    escapeValue: false,
  },
});

export const TranslationProvider = ({
  children,
}: TranslationProviderProps): ReactElement | null => (
  <Fragment>{children}</Fragment>
);
