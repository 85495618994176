import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { Logout } from './Logout';
import { api } from '../../api/axios.service';
import './Admin.scss';

interface IData {
  id: number;
  title: string;
  date: string;
  text: string;
  cardId: string;
  prefix: string;
  project: string;
  orders: number;
  images: string[];
}

interface IImages {
  id: number;
  card: string;
  status: boolean;
  images: string;
}

export const Edit = (): JSX.Element => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<IData>();
  const [images, setImages] = useState<IImages[]>([]);
  const [status, setStatus] = useState(false);

  const cookies = new Cookies();

  useEffect(() => {
    if (!cookies.get('token')) {
      navigate('/auth');
    }
  }, []);

  useEffect(() => {
    api.get({ uri: `/project-admin/${id}` }).then(({ status, data }) => {
      if (status === 200) {
        const { cardId } = data;

        api
          .get({ uri: `/project-admin-images/${cardId}` })
          .then(({ status, data }) => {
            status === 200 && setImages(data);
          });

        setData(data);
      }
    });
  }, []);

  const handlerUpdate = () => {
    const body = {
      id: data.id,
      title: data.title,
      date: data.date,
      text: data.text,
      project: data.project,
      orders: data.orders,
    };

    api
      .put({
        uri: '/update',
        data: body,
      })
      .then(() => {
        setStatus(true);

        setTimeout(() => {
          setStatus(false);
        }, 2500);
      });
  };

  const handlerChange = (type, value) => {
    setData((prev) => ({ ...prev, [type]: value }));
  };

  const handlerUpdateStatus = (id, status) => {
    const body = {
      id: id,
      status: status ? 1 : 0,
    };

    api
      .put({
        uri: '/update-image-status',
        data: body,
      })
      .then(() => {
        setStatus(true);

        setTimeout(() => {
          setStatus(false);
        }, 2500);
      });
  };

  const handlerChangeImages = ({ images, file }) => {
    const formData = new FormData();
    formData.append('images', images);
    formData.append('file', file);

    api
      .post({
        uri: '/update-image-item',
        data: formData,
      })
      .then(() => {
        setStatus(true);

        setTimeout(() => {
          setStatus(false);
        }, 2500);
      });
  };

  const handlerDelete = () => {
    const q = window.confirm('Удалить проект?');

    if (q) {
      api
        .get({
          uri: `/delete/${id}`,
        })
        .then(({ status }) => {
          status === 200 && navigate('/edit-list');
        });
    }
  };

  return (
    <div className="container">
      <div className="d-flex gap-2 justify-space-between align-center">
        <h1 className="p-1">Редактирование проекта ID: {data?.id}</h1>
        <button onClick={() => navigate('/edit-list')}> {'<--'} Назад</button>
        <Logout />
      </div>

      <button className="deleteProject" onClick={handlerDelete}>
        Удалить проект
      </button>

      <label className="d-flex gap-2 align-center mb-2">
        <p className="mb-0 min-w-title">Заголовок</p>
        <input
          onChange={(e) => handlerChange('title', e.target.value)}
          className="input"
          type="text"
          defaultValue={data?.title}
        />
      </label>

      <label className="d-flex gap-2 align-center mb-2">
        <p className="mb-0 min-w-title">Дата</p>
        <input
          onChange={(e) => handlerChange('date', e.target.value)}
          className="input"
          type="text"
          defaultValue={data?.date}
        />
      </label>

      <label className="d-flex gap-2 align-center mb-2">
        <p className="mb-0 min-w-title">Текст</p>
        <textarea
          onChange={(e) => handlerChange('text', e.target.value)}
          className="input"
          cols={30}
          rows={10}
          defaultValue={data?.text}
        />
      </label>

      <label className="d-flex gap-2 align-center mb-2">
        <p className="mb-0 min-w-title">Ссылка</p>
        <input
          onChange={(e) => handlerChange('project', e.target.value)}
          className="input"
          type="text"
          defaultValue={data?.project}
        />
      </label>

      <label className="d-flex gap-2 align-center mb-2">
        <p className="mb-0 min-w-title">ORDER BY</p>
        <input
          onChange={(e) => handlerChange('orders', e.target.value)}
          className="input"
          type="number"
          defaultValue={data?.orders}
        />
      </label>

      <div className="d-flex gap-3 pt-3 pb-3">
        {images.map(({ id, card, status, images }) => (
          <div className="imagesItem" key={id}>
            <img
              className="imageAdmin"
              src={`/image-project/${images}`}
              alt=""
            />
            <input
              onChange={(e) =>
                handlerChangeImages({
                  images: images,
                  file: e.target.files[0],
                })
              }
              type="file"
              name={card}
            />
            <label className="d-flex gap-2">
              <span>Показывать на сайте</span>
              <input
                type="checkbox"
                defaultChecked={status}
                onChange={(e) => handlerUpdateStatus(id, e.target.checked)}
              />
            </label>
          </div>
        ))}
      </div>

      <div className="d-flex">
        <button className="loginButton center" onClick={() => handlerUpdate()}>
          Сохранить
        </button>
      </div>
      {status && <p className="successUpdate">Данные успешно обновлены</p>}
    </div>
  );
};
