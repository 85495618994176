import { useTranslation } from 'react-i18next';

import { ProjectsItem } from './ProjectsItem';
import diplomas from '../../../assets/images/Project/diplomas.jpg';
import './Projects.scss';
import { useAppStore } from '../../../store/appStore';

export const Projects = (): JSX.Element => {
  const locales = useAppStore.use.locales();
  const { t } = useTranslation();

  const project = Object.entries(locales.ru.translation.Project).map(
    (items) => {
      const id = items[0];
      const item = items[1];

      return {
        images: item['images'],
        title: t(`Project.${id}.title`),

        date: t(`Project.${id}.date`),
        text: t(`Project.${id}.text`),
        project: item['project'],
        id: id,
      };
    },
  );

  return (
    <div className="container">
      <div className="diplomas">
        <img className="img-fluid" src={diplomas} alt="" />
      </div>
      {project.map(({ id, title, date, text, images, project }) => (
        <ProjectsItem
          key={id}
          id_card={id}
          id={project}
          date={date}
          images={images}
          text={text}
          title={title}
        />
      ))}
    </div>
  );
};
