import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { api } from '../../api/axios.service';

import './Admin.scss';

export const Logout = (): JSX.Element => {
  const cookies = new Cookies();
  const navigate = useNavigate();

  const logout = (): void => {
    api
      .get({
        uri: '/logout',
      })
      .then(({ status }) => {
        if (status === 200) {
          cookies.remove('token');
          navigate('/auth');
        }
      });
  };

  return (
    <button className="logoutButton" onClick={logout}>
      Выйти
    </button>
  );
};
