import { useState } from 'react';

import { api } from '../../api/axios.service';

import './Admin.scss';

interface IProps {
  data: { id: number; user: string; password: number; isActive: number };
}

export const User = ({ data }: IProps): JSX.Element => {
  const [type, setType] = useState(false);

  const handlerUpdate = (id, role, value) => {
    if (value?.key === 'Enter') {
      api.put({
        uri: `/update-user`,
        data: {
          id: id,
          role: role,
          value: value.target.value,
        },
      });
    }
  };

  const handleActive = (id, role, value) => {
    api.put({
      uri: `/update-user`,
      data: {
        id: id,
        role: role,
        value: value,
      },
    });
  };

  return (
    <div className="">
      <div className="">
        <hr />
        <div>
          <div className="gridUser">
            <label className="inputItem">
              <span className="labelInput">ID:</span>
              <input
                className="inputText"
                disabled
                type="text"
                defaultValue={data.id}
              />
            </label>
            <label className="inputItem">
              <span className="labelInput">Логин:</span>
              <input
                disabled
                className="inputText"
                type="text"
                defaultValue={data.user}
              />
            </label>
            <label className="inputItem">
              <span className="labelInput">Пароль:</span>
              <input
                className="inputText"
                type={type ? 'text' : 'password'}
                defaultValue={data.password}
                onKeyDown={(e) => handlerUpdate(data.id, 'password', e)}
              />
              <button
                style={{ minWidth: '7rem' }}
                onClick={() => setType(!type)}
              >
                {type ? 'Скрыть' : 'Показать'}
              </button>
            </label>
            <label className="inputItem">
              <span className="labelInput">Активен в системе:</span>
              <input
                className="inputChecbox"
                type="checkbox"
                defaultChecked={data.isActive === 1}
                onChange={(e) =>
                  handleActive(
                    data.id,
                    'isActive',
                    e.target.checked ? '1' : '0',
                  )
                }
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
