import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { Logout } from './Logout';
import { api } from '../../api/axios.service';

export const Create = (): JSX.Element => {
  const [status, setStatus] = useState(false);

  const navigate = useNavigate();

  const cookies = new Cookies();

  const [prefix, setPrefix] = useState<string>('ru');
  const [title, setTitle] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [text, setText] = useState<string>('');
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);
  const [orders, setOrders] = useState(null);

  useEffect(() => {
    if (!cookies.get('token')) {
      navigate('/auth');
    }
  }, []);

  const handlerCreate = () => {
    const formData = new FormData();
    formData.append('file1', file1);
    formData.append('file2', file2);
    formData.append('file3', file3);
    formData.append('title', title);
    formData.append('date', date);
    formData.append('text', text);
    formData.append('prefix', prefix);
    formData.append('orders', orders);

    api
      .post({
        uri: '/create',
        data: formData,
      })
      .then(({ status }) => {
        status === 200 && setStatus(true);

        setTimeout(() => {
          navigate('/edit-list');
        }, 2500);
      });
  };

  return (
    <div className="container">
      <div className="d-flex gap-2 justify-space-between align-center">
        <h1 className="p-1">Добавление проекта</h1>
        <button onClick={() => navigate('/edit-list')}>Назад</button>
        <Logout />
      </div>
      <label className="d-flex gap-2 align-center mb-2">
        <p className="mb-0 min-w-title">Язык</p>
        <select className="input" onChange={(e) => setPrefix(e.target.value)}>
          <option value="ru">Русский</option>
          <option value="en">Английский</option>
          <option value="zh">Китайский</option>
        </select>
      </label>
      <label className="d-flex gap-2 align-center mb-2">
        <p className="mb-0 min-w-title">Заголовок</p>
        <input
          className="input"
          type="text"
          onChange={(e) => setTitle(e.target.value)}
          defaultValue={title}
        />
      </label>
      <label className="d-flex gap-2 align-center mb-2">
        <p className="mb-0 min-w-title">Дата</p>
        <input
          className="input"
          type="text"
          onChange={(e) => setDate(e.target.value)}
          defaultValue={date}
        />
      </label>
      <label className="d-flex gap-2 align-center mb-2">
        <p className="mb-0 min-w-title">Текст</p>
        <textarea
          className="input"
          cols={30}
          rows={10}
          onChange={(e) => setText(e.target.value)}
          defaultValue={text}
        />
      </label>
      <label className="d-flex gap-2 align-center mb-2">
        <p className="mb-0 min-w-title">Номер</p>
        <input
          className="input"
          type="number"
          onChange={(e) => setOrders(e.target.value)}
          defaultValue={orders}
        />
      </label>
      <label className="d-flex gap-2 align-center mb-2">
        <p className="mb-0 min-w-title">Изображения</p>
      </label>
      <div className="d-grid">
        <input
          accept=".jpg, .png"
          className="pb-2"
          type="file"
          onChange={(e) => setFile1(e.target.files[0])}
        />
        <input
          accept=".jpg, .png"
          className="pb-2"
          type="file"
          onChange={(e) => setFile2(e.target.files[0])}
        />
        <input
          accept=".jpg, .png"
          className="pb-2"
          type="file"
          onChange={(e) => setFile3(e.target.files[0])}
        />
      </div>
      <div className="d-flex">
        <button className="loginButton center" onClick={() => handlerCreate()}>
          Создать
        </button>
      </div>
      {status && <p className="successUpdate">Данные успешно добавлены</p>}
    </div>
  );
};
